import React, {Component} from "react";
import {Link} from "react-router-dom";
import sprite from "../img/sprite.svg";

import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class Nav extends Component{

    render(){

        return <div className="menu">
            <Link to="/" className="company">BuroData Platform</Link>
            <ul className="nvg">
                <li className ="nvg-list">
                    <Link className="nvg-list__item" to="/#schema">{ transl[process.env.REACT_APP_LANG].NavLinkOne }
                        <Icon viewBox="0 0 11 16" icon="chevron-left" />
                    </Link>
                </li>
                <li className ="nvg-list">
                    <Link className="nvg-list__item" to="/#capability">{ transl[process.env.REACT_APP_LANG].NavLinkTwo }
                        <Icon viewBox="0 0 11 16" icon="chevron-left" />
                    </Link>
                </li>
                <li className ="nvg-list">
                    <Link className="nvg-list__item" to="/#partners">{ transl[process.env.REACT_APP_LANG].NavLinkThree }
                        <Icon viewBox="0 0 11 16" icon="chevron-left" />
                    </Link>
                </li>
                <li className ="nvg-list">
                    <a href={ transl[process.env.REACT_APP_LANG].NavLinkEn } target="_blank">{ transl[process.env.REACT_APP_LANG].NavLinkFour }
                        <Icon viewBox="0 0 11 16" icon="chevron-left" />
                    </a>
                </li>
            </ul>
            {this.props.children}
        </div>
    }
}