import React, { Component } from 'react';
import PropTypes from "prop-types";
import {BrowserRouter, Switch, Route, Link} from "react-router-dom";
import './Fonts.css';
import './App.css';

import Header from "./components/Header";
import Nav from "./components/Nav";
import Form from "./components/Form";
import Main from "./components/Main";
import SchemeClassical from "./components/SchemeClassical";
import SchemeCapability from "./components/SchemeCapability";
import SiemensCaseEn from "./components/SiemensCaseEn";
import RaiffeisenCaseEn from "./components/RaiffeisenCaseEn";


import Footer from "./components/Footer";
import Modal from "./components/Modal";
import sprite from "./img/sprite.svg";

import transl from "./components/Transl";
import Helmet from "react-helmet"

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            headerTheme: "",
            footerTheme: "dark",
            headerClass: "",
            footerClass: "",
            modal: false,
            screenSm: window.innerWidth < 1024
        }
    }

    setHeaderTheme = (theme) => {
        if (this.state.headerTheme != theme) this.setState({headerTheme: theme});
    }

    setHeaderClass = (classAdd) => {
        if (this.state.headerClass != classAdd) this.setState({headerClass: classAdd});
    }

    setFooterTheme = (theme) => {
        if (this.state.footerTheme != theme) this.setState({footerTheme: theme});
    }

    componentDidMount(){
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        if (this.state.screenSm !== window.innerWidth < 1024) {
            this.setState({screenSm: window.innerWidth < 1024});
        }
    }

    toggleModal = () => {

        this.setState((prevState) => {
            return {
                modal: !prevState.modal
            }
        }, () => {
            let scrollWidth = window.innerWidth - document.body.clientWidth;
            if (this.state.modal) {
                if (scrollWidth && !document.body.classList.contains("unscrollable")) {
                    document.body.classList.add("unscrollable");
                    document.body.setAttribute('style', 'padding-right:' + scrollWidth + 'px');
                    document.getElementById("modal").setAttribute('style', 'padding-right:' + scrollWidth + 'px');
                }
            } else {
                document.body.classList.remove("unscrollable");
                document.body.removeAttribute('style');
            }
        })
    }

    static childContextTypes = {
        toggleModal: PropTypes.func,
        setHeaderTheme: PropTypes.func,
        setHeaderClass: PropTypes.func,
        setFooterTheme: PropTypes.func,
        screenSm: PropTypes.bool,
        modal: PropTypes.bool
    };

    getChildContext() {
        return {
            toggleModal: this.toggleModal,
            setHeaderTheme: this.setHeaderTheme,
            setHeaderClass: this.setHeaderClass,
            setFooterTheme: this.setFooterTheme,
            modal: this.state.modal,
            screenSm: this.state.screenSm
        };
    }

    render() {

        var {
            headerTheme,
            headerClass,
            footerTheme,
            modal,
            screenSm
        } = this.state;

        return ( <BrowserRouter>
              <div className="App">
                  <Helmet
                      title={ transl[process.env.REACT_APP_LANG].indexTitle }
                      meta={[
                          {"name": "description", "content": transl[process.env.REACT_APP_LANG].indexMetaDiscr },
                          {"name": "Keywords", "content": transl[process.env.REACT_APP_LANG].indexMetaKeywords }
                      ]}
                  />
                  <Header theme={headerTheme} className={headerClass}>
                      <Link className="company" to="/" onClick={() => {window.scrollTo(0,0)}}>BuroData Platform</Link>
                      {screenSm ? <button className="burger-btn" onClick={this.toggleModal}>
                          <Icon viewBox="0 0 24 18" icon="menu-burger" />
                      </button> : <Nav>
                          <div className={"btn "+(headerTheme === "light" ? "btn--DarkBlue" : "btn--white")}
                                onClick={this.toggleModal}
                          >
                              { transl[process.env.REACT_APP_LANG].BtnLeaveInquiry }
                          </div>
                      </Nav>}
                  </Header>

                  <Switch>
                      <Route path="/" exact component={Main}/>
                      <Route path="/classic" component={SchemeClassical}/>
                      <Route path="/opportunities" component={SchemeCapability}/>
                      <Route path="/siemens" component={SiemensCaseEn}/>
                      <Route path="/raiffeisen" component={RaiffeisenCaseEn}/>
                  </Switch>

                  <Footer className={footerTheme}/>
                  {modal ? <Modal className="modalClass" onClose={this.toggleModal}>
                      {screenSm ? <Nav onClick={this.toggleModal}/> : null}
                      <Form id={"chekTwo"} className="modal-form"/>
                  </Modal> : null}
              </div>
        </BrowserRouter>);
    }
}

export default App;
