import React, {Component} from "react";

export default class CaseEnFirstScr extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"project-en-bg"+(className ? " "+className : "")} />
    }

}