import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import scheme from "../img/shema-dark.png";
import schemeEn from "../img/shema-dark@3x-En.png";
import sprite from "../img/sprite.svg";

import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class SchemeClassical extends Component{

    constructor() {
        super();

        this.state = {
            shemEN: transl[process.env.REACT_APP_LANG].SchemeClassicalEn,
        }
    }

    static contextTypes = {
        setHeaderTheme: PropTypes.func,
        setFooterTheme: PropTypes.func
    }

    componentDidMount(){
        this.context.setHeaderTheme("dark");
        this.context.setFooterTheme("dark");
    }

    render(){

        let {
            className = ""
        } = this.props;

        let {
            shemEN
        } = this.state;

        return <div className={"SchemeClassical "+(className ? " "+className : "")}>

            <div className="wrap wrap--scheme">

                <Link className="Scheme-link" to="/#schema">
                    <Icon viewBox="0 0 60 60" icon="back" />
                    { transl[process.env.REACT_APP_LANG].BtnBack }
                </Link>
                <div className="Scheme-head">
                    <div className="Scheme-head__capt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].SchemeClassicalCapt }}/>
                    <div className="Scheme-head__dsc">
                        <div className="head__dsc-layer">
                            <Icon viewBox="0 0 26 21" icon="triangle-gr" />
                            { transl[process.env.REACT_APP_LANG].SchemeClassicalLabel01 }
                        </div>
                        <div className="head__dsc-database">
                            <Icon viewBox="0 0 26 26" icon="circle-bl" />
                            { transl[process.env.REACT_APP_LANG].SchemeClassicalLabel02 }
                        </div>
                    </div>
                </div>
            </div>
            {shemEN ? <img className="ShemaEnDark Shema--Dark Scheme-head--dark" src={ schemeEn }/> :
                <img className="Shema Shema--Dark Scheme-head--dark" src={ scheme }/>}

        </div>
    }

}