import React, {Component} from "react";

import transl from "./Transl";

export default class BuroDataTransf extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"BuroDataTransf BuroDataTxt"+(className ? " "+className : "")}>
            <div className="BuroDataTxt__capt">
                Digital transformation
            </div>
            <div className="BuroDataTransf__desc BuroDataTxt__desc" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].BuroDataTransfCapt}} />
        </div>
    }

}