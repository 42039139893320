import React, {Component} from "react";

import transl from "./Transl";

export default class BuroDataTxt extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"BuroDataTxt"+(className ? " "+className : "")}>
            <div className="BuroDataTxt__capt"></div>
            <div className="BuroDataTxt__desc" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].BuroDataTxtCapt}} />
        </div>
    }

}