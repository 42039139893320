import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import sprite from "../img/sprite.svg";
import transl from "./Transl";

import CaseEnFirstScr from "./CaseEn/CaseEnFirstScr";
import CaseEnFirstScrTxt from "./CaseEn/CaseEnFirstScrTxt";
import CaseEnInfo from "./CaseEn/CaseEnInfo";
import CaseEnOtherCases from "./CaseEn/CaseEnOtherCases";
import laptop from "../img/laptop_1.png";
import screens from "../img/screens.png";
import screens320 from "../img/screens_320.png";
import phone from "../img/iphones.png";
const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class SiemensCaseEn extends Component{

    constructor() {
        super();

        this.state = {
            firstScreenTxt: {
                clientItem: 'Siemens',
                serviceTxt01: 'Digital',
                serviceTxt02: 'UI/UX',
                serviceLink01: 'https://buroburo.ru/projects/?TAG=49',
                serviceLink02: 'https://buroburo.ru/projects/?TAG=47',
                txtCapt01: 'Task',
                txtPar01: 'Optimize, digitalize and monetize business processes in&nbsp;communication with clients of&nbsp;the leasing division of&nbsp;Siemens in Russia with the possibility to&nbsp;scale to&nbsp;other countries.',
                txtCapt02: 'Implementation',
                txtPar02: 'To&nbsp;solve the client&rsquo;s problems, a&nbsp;single ecosystem was implemented on&nbsp;the BuroData platform, containing two user scenarios:&nbsp;a&nbsp;personal account for the company&rsquo;s clients and a&nbsp;distinguished one for the client managers.',
            },
            customerArea: {
                img: laptop,
                capt: 'Customer area',
                class: 'laptop-img',
                description: [
                    {
                        txt: 'Functional of&nbsp;personal accounts was created to&nbsp;simplify operations under leasing contracts as&nbsp;much as&nbsp;possible, prepare commercial offers for leasing, receive completed transactions documents, receive reconciliation reports and other documents, and track all stages of&nbsp;the commercial offer formation and conclude contracts, as&nbsp;well as&nbsp;all financial transactions in&nbsp;real time; monetize the communication of&nbsp;new as&nbsp;well as&nbsp;existing customers with Siemens representatives.'
                    },
                    {
                        txt: 'Client&rsquo;s personal account became a&nbsp;single authorization window. All documents are now stored in&nbsp;the same ecosystem and automatically pulled up&nbsp;to&nbsp;create new proposals by&nbsp;the soulless machine, in&nbsp;our case&nbsp;&mdash; the BuroData Platform. In&nbsp;addition, when filling out all forms, the system automatically checks all the data entered by&nbsp;the client in&nbsp;manual mode (addresses, bank details and personal data, such as&nbsp;the spelling of&nbsp;last names, TIN, etc.), thus minimizing the risk of&nbsp;making an&nbsp;error and sending information to&nbsp;the right place in&nbsp;the right format.'
                    },
                    {
                        txt: 'As&nbsp;the whole process is&nbsp;monetized, users get access to&nbsp;existing contracts, related financial transactions and documents.'
                    },
                    {
                        txt: 'Let&rsquo;s take a&nbsp;dairy plant as&nbsp;an&nbsp;example of&nbsp;a&nbsp;leased asset. As&nbsp;part of&nbsp;this leasing, many other leasing contracts are made: for equipment, delivery trucks, tractors, etc. And for each leasing contract customers see a&nbsp;schedule, the date of&nbsp;the next payment, delay (if&nbsp;any), and can independently form an&nbsp;account, see reports on&nbsp;receipt of&nbsp;funds for the payment of&nbsp;the next payment, can attach a&nbsp;payment order to&nbsp;the payment made, etc.'
                    },
                    {
                        txt: 'The BuroData platform aggregates, processes and spreads all the data on&nbsp;various customer systems: CRM, Accounting system, leasing systems, and others. We&nbsp;built a&nbsp;logical relationship that previously occurred by&nbsp;repeatedly exchanging the drafts of&nbsp;the documents before making any suitable changes. Now the client makes changes to&nbsp;the finished contract on&nbsp;his personal page, downloads the copy approved by&nbsp;both parties, signs and sends away this piece of&nbsp;paper. But even this process will soon be&nbsp;simplified by&nbsp;the introduction of&nbsp;the digital signature.'
                    },
                    {
                        txt: 'To&nbsp;resolve any issues in&nbsp;the personal account, a&nbsp;chat has been created where authorized users can communicate with client managers and customer support.'
                    }
                ]
            },
            clientArea: {
                img: screens,
                img320: screens320,
                capt: 'Client Manager Area',
                class: 'client-img',
                description: [
                    {
                        txt: 'Client manager have their own tools for work.'
                    },
                    {
                        txt: 'What\'s it for?'
                    },
                    {
                        txt: 'For example, the client made a&nbsp;mistake when filling out the form. Unfortunately, not all mistakes in&nbsp;the manually filled in&nbsp;information can be&nbsp;registered automatically, while the manager can edit the application or&nbsp;completely fill out the application on&nbsp;behalf of&nbsp;the client. The entire history of&nbsp;edits is&nbsp;preserved and is&nbsp;transparent to&nbsp;both sides.'
                    },
                    {
                        txt: 'At&nbsp;the initial stage, we&nbsp;also retained the access to&nbsp;the interface for client managers with the ability to&nbsp;upload documents sent to&nbsp;them by&nbsp;the client over email to&nbsp;their personal account in&nbsp;case of&nbsp;them not having access to&nbsp;the system for any reason. However, we&nbsp;plan to&nbsp;soon automate this process.'
                    },
                ]
            },

            phones: {
                img: phone,
                classImg: 'phone-img',
                phonesDsc: [
                        {
                            description: [
                            {
                                capt: 'Scaling',
                            },
                            {
                                txt: 'Russia is&nbsp;on&nbsp;the short list of&nbsp;countries (on&nbsp;par with China and Japan), prone to&nbsp;development and introduction of&nbsp;innovations in&nbsp;various areas of&nbsp;life and production, and digital field is&nbsp;no&nbsp;exception. That is&nbsp;why Siemens headquarters chose the Russian market as&nbsp;a&nbsp;contractor for creating a&nbsp;platform for testing a&nbsp;new leasing web-platform, after which other languages and localization in&nbsp;other countries are to&nbsp;be&nbsp;added to&nbsp;the site, but that&rsquo;s just a&nbsp;matter of&nbsp;content.'
                            },
                            {
                                txt: 'The architecture of&nbsp;the BuroData Platform, which we&nbsp;have proposed, is&nbsp;ideal for working with a&nbsp;large information array, it&nbsp;is&nbsp;easily scaled. Now only the desktop version is&nbsp;in&nbsp;beta testing with the client, but it&nbsp;is&nbsp;fully adaptive. With desktop version as&nbsp;a&nbsp;base, after the publication of&nbsp;the first two releases, a&nbsp;mobile application will be&nbsp;launched, where users will be&nbsp;able to&nbsp;monitor the status of&nbsp;contracts, set up&nbsp;push notifications about the date and amount of&nbsp;payments and get access to&nbsp;all the required data, to&nbsp;be&nbsp;aware of&nbsp;the situation on&nbsp;leasing transactions without a&nbsp;need to&nbsp;access the computer.'
                            },
                        ]
                        },

                        {
                            description: [
                            {
                                capt: 'Outstaffing',
                            },
                            {
                                txt: 'For a&nbsp;quick and high-quality execution of&nbsp;the most complex and risky stages of&nbsp;design, we&nbsp;have implemented a&nbsp;system of&nbsp;outstaffing. Employees of&nbsp;the Bureaubureau went to&nbsp;Vladivostok, to&nbsp;the Siemens office, where during the week, in&nbsp;close cooperation with the whole project team, they worked on&nbsp;a&nbsp;strict schedule:'
                            },
                            {
                                txt: 'A&nbsp;meeting in&nbsp;the morning of&nbsp;the entire working group to&nbsp;discuss the latest prototypes, planning the next steps;'
                            },
                            {
                                txt: 'Free time to&nbsp;process the feedback received;'
                            },
                            {
                                txt: 'A&nbsp;second planning meeting in&nbsp;the afternoon to&nbsp;discuss the results of&nbsp;the current stage of&nbsp;work and the transition to&nbsp;the next stage.'
                            },
                            {
                                txt: 'It&nbsp;was thanks to&nbsp;outstaffing that we&nbsp;were able to&nbsp;complete the same amount of&nbsp;work in&nbsp;a&nbsp;week, the implementation of&nbsp;which with remote interaction can stretch for up&nbsp;to&nbsp;one and a&nbsp;half months.'
                            },
                            {
                                txt: 'According to&nbsp;the same system, we&nbsp;worked with RosEvroBank to&nbsp;ensure that the design and creation stages of&nbsp;the online bank were completed in&nbsp;a&nbsp;very short time. You can read more about the process of&nbsp;creating a&nbsp;financial platform for RosEvroBank here.'
                            },
                        ]
                        }
                    ]

            },

            otherCases: {
                capt: 'Other cases',
                otherCasesTxt: [
                    {
                        capt: 'Raiffeisen Capital - full control over investments',
                        classBg: 'raiffCase',
                        link: '/raiffeisen',
                        linkExternal: true,
                    },
                    {
                        capt: 'Motiv - Multifunctional web-platform',
                        classBg: 'motiveCase',
                        link: 'https://motivtelecom.ru/',
                        linkExternal: false,
                    }
                ],
            }

        }
    }

    static contextTypes = {
        setHeaderTheme: PropTypes.func,
        setFooterTheme: PropTypes.func,
        setHeaderClass: PropTypes.func
    }

    componentDidMount(){
        this.context.setHeaderTheme("light");
        this.context.setFooterTheme("light");
        this.context.setHeaderClass("siemens-case-en-header");
    }

    componentWillUnmount(){
        this.context.setHeaderClass("");
    }

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"project-en "+(className ? " "+className : "")}>

            <div className="wrap wrap--scheme">

                <Link className="Scheme-link" to="/#partners">
                    <Icon viewBox="0 0 60 60" icon="back" />
                    { transl[process.env.REACT_APP_LANG].BtnBack }
                </Link>
                <div className="Scheme-head">
                    <div className="Scheme-head__capt Scheme-head__capt--light">
                        Siemens Leasing<br/> Platform
                    </div>
                </div>
            </div>

            <div className="wrap--indent">
                <CaseEnFirstScr className="siemens-case-bg" />
                <CaseEnFirstScrTxt firstScreenTxt={ this.state.firstScreenTxt } />
            </div>

            <CaseEnInfo items={ this.state.customerArea } />

            <CaseEnInfo items={ this.state.clientArea } />

            <CaseEnInfo type={ "typeB" } className={ 'info-txt-ul' } phonesItem={ this.state.phones } />

            <div className="wrap--indent">
                <CaseEnOtherCases items={ this.state.otherCases }/>
            </div>

        </div>
    }

}