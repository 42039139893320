import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import sprite from "../img/sprite.svg";
import transl from "./Transl";
import CaseEnFirstScr from "./CaseEn/CaseEnFirstScr";
import CaseEnFirstScrTxt from "./CaseEn/CaseEnFirstScrTxt";
import CaseEnInfo from "./CaseEn/CaseEnInfo";
import CaseEnGridScreen from "./CaseEn/CaseEnGridScreen";
import CaseEnOtherCases from "./CaseEn/CaseEnOtherCases";
import scenario from "../img/scenario-img.jpg";
import macbook from "../img/macbook_1.png";
import raiffScreen03 from "../img/raiff-screen03.jpg";
import gridScreen01 from "../img/grid_screen_1.jpg";
import gridScreen02 from "../img/grid_screen_2.jpg";
import gridScreen03 from "../img/grid_screen_3.jpg";
import gridScreen04 from "../img/grid_screen_4.jpg";
import gridScreen05 from "../img/grid_screen_5.jpg";
import gridScreen06 from "../img/grid_screen_6.jpg";
import raiffScreen04 from "../img/raiff-screen04.png";
import raiffScreen05 from "../img/raiff-screen05.jpg";
import raiffScreen07 from "../img/raiff-screen07.jpg";
const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class RaiffeisenCaseEn extends Component{

    constructor() {
        super();

        this.state = {
            firstScreenTxt: {
                clientItem: 'Raiffeisen Capital',
                serviceTxt01: 'Digital',
                serviceTxt02: 'Finance',
                serviceTxt03: 'Support',
                serviceLink01: 'https://buroburo.ru/projects/?TAG=49',
                serviceLink02: 'https://buroburo.ru/projects/?TAG=45',
                serviceLink03: 'https://buroburo.ru/projects/?TAG=19',
                txtCapt01: null,
                txtPar01: 'Almost all management companies have a&nbsp;client personal account. How does the client personal account of&nbsp;the management company&nbsp;X differ from the ones of&nbsp;a&nbsp;hundred other management companies? It&nbsp;virtually doesn&rsquo;t: different colors, various button placement with slightly differing functionality&nbsp;&mdash; that&rsquo;s the whole difference. And we&nbsp;decided to&nbsp;implement a&nbsp;fundamentally new approach.',
                txtCapt02: 'Idea',
                txtPar02: 'Creating a&nbsp;single financial platform through which the user can manage their own investment portfolio.',
            },

            implement: {
                img: macbook,
                capt: 'Implementation',
                class: 'laptop-img',
                description: [
                    {
                        txt: 'The portal is&nbsp;implemented on&nbsp;our author&rsquo;s platform&nbsp;&mdash; <a class="implement-link" href="https://burodata.com">BuroData Platform</a> which allows to&nbsp;integrate very quickly the website, the front of&nbsp;a&nbsp;personal account and mobile application into a&nbsp;uniform entity.'
                    },
                    {
                        txt: 'Besides, at&nbsp;the project there are inclinations of&nbsp;a&nbsp;blockchain: the system collects data from different databases from the customer, aggregates in&nbsp;one array and creates logical interrelations between them. This development is&nbsp;convenient that we&nbsp;have a&nbsp;uniform web platform and one universal API which interacts both with the website, and with the personal account having the raised security system&nbsp;&mdash; as&nbsp;at&nbsp;any Internet banking.'
                    },
                ]
            },

            customerArea: {
                img: scenario,
                capt: 'Scenarios',
                class: 'security-img02',
                description: [
                    {
                        txt: 'When logging in&nbsp;bank customers are presented with a&nbsp;choice of&nbsp;two types of&nbsp;user experience:'
                    },
                    {
                        txt: 'Private individual&nbsp;&mdash; any individual who can register on&nbsp;the portal after passing through a&nbsp;specific procedure, for example, signing an&nbsp;agreement at&nbsp;a&nbsp;local bank branch. But in&nbsp;the future, the investment portfolio is&nbsp;managed from the personal account on&nbsp;the desktop, smartphone, or&nbsp;tablet&nbsp;&mdash; it&nbsp;does not matter.'
                    },
                    {
                        txt: 'Personal account functional (client):'
                    },
                    {
                        txt: 'Authorization through the public services portal'
                    },
                    {
                        txt: 'Overview of expired documents'
                    },
                    {
                        txt: 'An electronic signature for documents'
                    },
                    {
                        txt: 'Purchase of&nbsp;investment products with a&nbsp;bank-issued card'
                    },
                    {
                        txt: 'Purchase of&nbsp;investment products through cashless payment'
                    },
                    {
                        txt: 'Accounting operations comment compilation'
                    },
                    {
                        txt: 'Chat with the management company staff'
                    },
                    {
                        txt: 'Overview of&nbsp;the transaction history and comments on&nbsp;investment products'
                    },
                    {
                        txt: 'Applying changes to&nbsp;the investor questionnaire'
                    },
                    {
                        txt: 'Maintaining the relevance of&nbsp;the personal information in&nbsp;the questionnaire and all related documents.'
                    }

                ]
            },
            implementation: {
                img: raiffScreen03,
                description: [
                    {
                        txt: 'A&nbsp;specific sector private client investments attracting Agent.'
                    },
                    {
                        txt: 'Personal account functional (agent):'
                    },
                    {
                        txt: 'Closed access to&nbsp;the system'
                    },
                    {
                        txt: 'New customer creation'
                    },
                    {
                        txt: 'Editing existing customer data'
                    },
                    {
                        txt: 'A&nbsp;range of&nbsp;available operations in&nbsp;the client&rsquo;s portfolio'
                    },
                    {
                        txt: 'Creating letters of&nbsp;attorney for authorized client representatives'
                    },
                    {
                        txt: 'Both the Basic and the Advanced search for customers in&nbsp;the database'
                    },
                    {
                        txt: 'Distinguished access for different agents of&nbsp;the management company'
                    },
                    {
                        txt: 'Reports compilation on&nbsp;operations for the day/ for a&nbsp;period'
                    },
                    {
                        txt: 'Access to&nbsp;document verification for the agent administrator before approval'
                    },
                    {
                        txt: 'Chat functionality to&nbsp;process customer requests'
                    },
                    {
                        txt: 'Compilation of&nbsp;sets of&nbsp;pdf documents for each operation'
                    }
                ]
            },

            gridScreenItemOne: [
                {
                    img: gridScreen01
                },
                {
                    img: gridScreen03
                },
                {
                    img: gridScreen05
                },
            ],
            gridScreenItemTwo: [
                {
                    img: gridScreen02
                },
                {
                    img: gridScreen04
                },
                {
                    img: gridScreen06
                },
            ],
            design: {
                img: null,
                capt: 'Design',
                class: null,
                description: [
                    {
                        txt: 'Based on&nbsp;the principle of&nbsp;non-toxicity, the personal account and the mobile application are combined into a&nbsp;single design system.'
                    },
                    {
                        txt: 'Not every management company can show off a&nbsp;complete set of&nbsp;user platforms (desktop page and mobile application), especially those realized in&nbsp;one ecosystem. Oftentimes, the desktop page is&nbsp;created by&nbsp;one developer, and only after a&nbsp;long time and several digital-transformation epochs pass is&nbsp;the mobile application created by&nbsp;already completely different developers, which results in&nbsp;a&nbsp;fundamental difference between one and the other.'
                    },
                ]
            },
            securityItems01: {
                img: raiffScreen04,
                capt: 'Security',
                class: "security-img01",
                description: [
                    {
                        txt: 'To&nbsp;ensure a&nbsp;high level of&nbsp;security, an&nbsp;independent we&nbsp;invited an&nbsp;auditor from &laquo;Digital Security&raquo;&nbsp;&mdash; one of&nbsp;the leading Russian consulting companies in&nbsp;the field of&nbsp;information security.'
                    },
                    {
                        txt: 'Digital security specialists conducted an&nbsp;audit of&nbsp;the software code that we&nbsp;wrote for any possible vulnerabilities and prepared an&nbsp;expert report. For the customer, this is&nbsp;a&nbsp;guarantee that the site and its users cannot be&nbsp;hacked, and for&nbsp;us&nbsp;&mdash; that we&nbsp;have done our job well and won&rsquo;t lose our reputation of&nbsp;a&nbsp;high-quality developer. That&rsquo;s why we&nbsp;used the services of&nbsp;a&nbsp;third party&nbsp;&mdash; an&nbsp;independent expert who audited our program code&nbsp;&mdash; to&nbsp;make absolutely sure.'
                    },
                    {
                        txt: 'We&nbsp;focused on&nbsp;increased security measures for &laquo;Raiffeisen Capital&raquo; clients during the authorization phase.'
                    },
                ]
            },
            securityItems02: {
                img: raiffScreen05,
                capt: null,
                class: "security-img02",
                description: [
                    {
                        txt: 'The authorization module, which is&nbsp;used to&nbsp;log users into the personal account, was developed by&nbsp;us from scratch and without false modesty can be&nbsp;considered the best in&nbsp;the financial sector (unfortunately, the comparison is&nbsp;only subjective: information about the authorization systems of&nbsp;other banks is&nbsp;confidential and is&nbsp;not subject to&nbsp;disclosure).'
                    },
                    {
                        txt: 'As&nbsp;part of&nbsp;the product we&nbsp;have created, more than 10&nbsp;levels of&nbsp;security checks that are performed in&nbsp;the background at&nbsp;each user login to&nbsp;the have been implemented. In&nbsp;fact, the system knows a&nbsp;little more about the client than it&nbsp;seems.'
                    },
                    {
                        txt: 'For example, there is&nbsp;an&nbsp;automatic check by&nbsp;the database of&nbsp;stolen passports, by&nbsp;the database of&nbsp;terrorist, and by&nbsp;the relevance of&nbsp;the documents, wherein an&nbsp;event of&nbsp;any changes, the system will inform you and ask you to&nbsp;make edits or&nbsp;confirm the information.'
                    },
                ]
            },
            securityItems03: {
                img: raiffScreen07,
                capt: null,
                class: "security-img03",
                description: [
                    {
                        txt: 'If&nbsp;the authorization takes place in&nbsp;the mode of&nbsp;electronic digital signature, then everything is&nbsp;solved online. Otherwise, to&nbsp;confirm some changes, such as&nbsp;replacing a&nbsp;passport, you will need to&nbsp;go&nbsp;to&nbsp;a&nbsp;local branch of&nbsp;the bank and physically confirm the document.'
                    },
                    {
                        txt: 'Also, classic security measures were implemented for users of&nbsp;online banks: during authorization, customers undergo double verification via SMS&nbsp;or, if&nbsp;the mobile application is&nbsp;installed, via push (if&nbsp;it&nbsp;was enabled in&nbsp;settings) and a&nbsp;limit for session duration. When the mobile application is&nbsp;launched on&nbsp;an&nbsp;unfamiliar device, you will need to&nbsp;confirm your identity with the smartphone connected to&nbsp;the personal account. By&nbsp;analogy, as&nbsp;implemented in&nbsp;Gmail. If&nbsp;there is&nbsp;no&nbsp;confirmation, the system will not allow the user to&nbsp;perform operations.'
                    },
                ]
            },

            otherCases: {
                capt: 'Other cases',
                otherCasesTxt: [
                    {
                        capt: 'Siemens Leasing Platform',
                        classBg: 'siemensCase',
                        link: '/siemens',
                        linkExternal: true,
                    },
                    {
                        capt: 'Motiv - Multifunctional web-platform',
                        classBg: 'motiveCase',
                        link: 'https://motivtelecom.ru/',
                        linkExternal: false,
                    },
                ],
            }

        }
    }

    static contextTypes = {
        setHeaderTheme: PropTypes.func,
        setFooterTheme: PropTypes.func,
        setHeaderClass: PropTypes.func
    };

    componentDidMount(){
        this.context.setHeaderTheme("light");
        this.context.setFooterTheme("light");
        this.context.setHeaderClass("siemens-case-en-header");
    }

    componentWillUnmount(){
        this.context.setHeaderClass("");
    }

    render(){

        return <div className="project-en raiffeisen-en">

            <div className="wrap wrap--scheme">

                <Link className="Scheme-link" to="/#partners">
                    <Icon viewBox="0 0 60 60" icon="back" />
                    { transl[process.env.REACT_APP_LANG].BtnBack }
                </Link>
                <div className="Scheme-head">
                    <div className="Scheme-head__capt Scheme-head__capt--light">
                        &laquo;Raiffeisen Capital&raquo;: full&nbsp;control over investments
                    </div>
                </div>
            </div>

            <div className="wrap--indent">
                <CaseEnFirstScr className="raiffeisen-case-bg" />
                <CaseEnFirstScrTxt firstScreenTxt={ this.state.firstScreenTxt } />
            </div>

            <CaseEnInfo items={ this.state.implement } />

            <CaseEnInfo type={ "typeA" } className="raiffeisen-en-implement" scenariosOne={ this.state.customerArea } scenariosTwo={ this.state.implementation } />

            <CaseEnGridScreen itemOne={ this.state.gridScreenItemOne } itemTwo={ this.state.gridScreenItemTwo }/>

            <CaseEnInfo items={ this.state.design } />

            <CaseEnInfo items={ this.state.securityItems01 } />

            <CaseEnInfo className="wrap-img-block" items={ this.state.securityItems02 } />

            <CaseEnInfo className="wrap-img-block" items={ this.state.securityItems03 } />

            <div className="wrap--indent">
                <CaseEnOtherCases items={ this.state.otherCases }/>
            </div>

        </div>
    }

}