import React, {Component} from "react";
import sprite from "../img/sprite.svg";
import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class Footer extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"Footer "+(className ? " "+className : "")}>
            <div className="wrap--wide">
                <div className="FooterCont">
                    <div className="copyright">© 2006 – {(new Date(Date.now())).getFullYear()} бюробюро</div>
                    <a className="phone" href="tel:+74955323880">+7 (495) 532-38-80</a>
                    <a className="email" href="mailto:mail@burodata.ru">mail@burodata.ru</a>
                </div>
                <a className="FooterDone" href="https://buroburo.ru/" target="_blank">
                    { transl[process.env.REACT_APP_LANG].FooterCpr01 }
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].FooterCpr02 }}/>
                    { transl[process.env.REACT_APP_LANG].FooterCpr03 }
                    <Icon viewBox="0 0 210 21" icon="heart" />
                </a>
            </div>
        </div>
    }

}