import React, {Component} from "react";
import {Link} from "react-router-dom";

import scheme from "../img/buroPlatformWhite@2x.png";

import transl from "./Transl";

export default class Capability extends Component{

    render(){

        let {
            className = "",
            history
        } = this.props;

        return <div id="capability" className={"Capability "+(className ? " "+className : "")}>
            <div className="CapabilityTxt wrap">
                <div className="SecondaryCaption SecondaryCaption--SecColor SecondaryCaption--CapabilityIndent">
                    <h2 className="SecondaryCaptionTitle SecondaryCaptionTitle--Capability" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].CapabilityCapt}} />
                    {/*<p className="SecondaryCaptionTxt SecondaryCaptionTxt--indent">Платформа позволяет избежать всех трудностей на&nbsp;проекте, т.к. решение построено <br className="capabil--br"/>на&nbsp;единой web-платформе с&nbsp;&laquo;Одним окном&raquo; для интеграции всех необходимых систем.</p>*/}
                    <p className="SecondaryCaptionTxt SecondaryCaptionTxt--indent" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].CapabilityDscr}} />
                    <Link to="/opportunities"
                          className="btn btn--DarkBlue btn--DarkBlue-indent"
                          onClick={(e) => {
                              e.preventDefault();
                              history.replace("/#capability");
                              history.push("/opportunities");
                          }}
                    >
                        { transl[process.env.REACT_APP_LANG].BtnSeeScheme }
                    </Link>
                </div>
            </div>
            <img className="CapabilityPlatformWhite" src={scheme}/>
        </div>
    }

}