import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Header extends Component{

    static contextTypes = {
        isFirstScreen: PropTypes.bool
    }

    render(){

        let {
            className = "",
            children,
            theme = "dark"
        } = this.props;

        return <div className={"header-wrapper"+(this.context.isFirstScreen ? " transparent" : " "+theme)}>
            <div className="wrap--wide">
                <div className={"header"+(className ? " "+className : "")}>
                    {children}
                </div>
            </div>
        </div>
    }
}