import React, {Component} from "react";
import PropTypes from "prop-types";

import {ParticlesBackground} from "./ParticlesBackground";
import sprite from "../img/sprite.svg";

import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class Particles extends Component{

    constructor(props){
        super(props);

        this.title = "BuroData Platform";
        var transparentLetters = [];
        if (window.innerWidth >= 768) {
            for (var i = 0; i < this.title.length; i++) {
                transparentLetters.push(i);
            }
        }
        this.state = {
            transparentLetters
        }
    }

    componentDidMount(){
        this.animateInterval = setInterval(() => {
            var transparentLetters = [...this.state.transparentLetters];
            if (transparentLetters.length == 0) clearInterval(this.animateInterval);
            var rand = (transparentLetters.length - 1)*Math.random();
            transparentLetters.splice(rand, 1);
            this.setState({transparentLetters});
        }, 75);
    }

    componentWillUnmount(){
        clearInterval(this.animateInterval);
    }


    static contextTypes = {
        isFirstScreen: PropTypes.bool,
        toggleModal: PropTypes.func
    }

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"Particles"+(className ? " "+className : "")+(this.context.isFirstScreen ? " " : " dark")}>
            <div className="particles">
                <ParticlesBackground />
                <div className="particles__content">
                    <h1 className="particles__capt">
                        {this.title.split('').map(
                            (letter, key) => <span key={key}
                                                   className={this.state.transparentLetters.indexOf(key) === -1 ? "visible" : ""}>
                                {letter}
                            </span>
                        )}
                    </h1>
                    <span className="particles__dsc" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].ParticlesCapt}} />
                    <div className="wrap">
                        <div className="btn btn--white btn--particles" onClick={this.context.toggleModal} dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].BtnLeaveInquiry}} />
                    </div>
                </div>
            </div>
            <div className="scrollDown">
                <div className="scrollDown__line-with-arrow">
                    <Icon viewBox="0 0 11 5" icon="triangle" />
                    <div className="scrollDown__line"/>
                </div>
                <span className="scrollDown__txt">Scroll down</span>
            </div>
        </div>
    }
}