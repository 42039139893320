import React, {Component} from "react";
import PropTypes from "prop-types";
import {apiUrl} from "../tools/config";
import Modal from "./Modal";
import sprite from "../img/sprite.svg";

import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class Form extends Component{

    constructor(props){
        super(props);

        this.state = this.getInitState();
    }

    getInitState = () => {
        return {
            fields: {
                name: {
                    type: "text",
                    label: transl[process.env.REACT_APP_LANG].FormFieldName,
                    value: "",
                    error: "",
                    className: "UserName",
                    required: true
                },
                phone: {
                    type: "text",
                    label: transl[process.env.REACT_APP_LANG].FormFieldPhone,
                    value: "",
                    error: "",
                    className: "UserPhone",
                    mask: this.phoneMask,
                    required: true
                },
                company: {
                    type: "text",
                    label: transl[process.env.REACT_APP_LANG].FormFieldCompany,
                    value: "",
                    error: "",
                    className: "Company",
                    groupClassName: "Form__CompString"
                },
                message: {
                    type: "textarea",
                    label: transl[process.env.REACT_APP_LANG].FormFieldMessage,
                    value: "",
                    error: "",
                    className: "Message"
                }
            },
            confirm: false,
            capcha: {
                text: this.getCapcha(),
                value: "",
                error: ""
            },
            isValid: false,
            isSent: false
        }
    }

    static contextTypes = {
        toggleModal: PropTypes.func,
        modal: PropTypes.bool
    }

    componentDidMount(){
        this.reloadCapcha();
    }

    getCapcha = () => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    reloadCapcha = () => {
        var capcha = {...this.state.capcha};
        capcha.text = this.getCapcha();
        this.setState({capcha});

    }

    onChange = (field) => {
        return (e) => {
            var fields = {...this.state.fields};

            fields[field].value = fields[field].mask ? fields[field].mask(e.target.value) : e.target.value;
            this.setState({fields}, () => {
                this.setState({isValid: this.isValid()});
            });
        }
    }

    onCapchaChange = (e) => {
        var capcha = {...this.state.capcha};
        capcha.value = e.target.value;
        this.setState({capcha}, () => {
            this.setState({isValid: this.isValid()});
        });
    }

    onBlur = (field) => {
        return (e) => {
            var fields = {...this.state.fields};
            var val = e.target.value;

            if (field == 'phone' && val == "+7") {
                val = "";
            }
            fields[field].value = val;

            if (fields[field].required) {
                fields[field].error = val ?
                    (field == 'phone' && val.replace(/\D/gim, '').length != 11 ? transl[process.env.REACT_APP_LANG].FormCaptchaPhone : "")
                    : transl[process.env.REACT_APP_LANG].FormCaptchaRequiredField;
            }

            this.setState({fields}, () => {
                this.setState({isValid: this.isValid()});
            });
        }
    }

    onFocus = (field) => {
        return () => {
            if (field == 'phone') {
                var fields = {...this.state.fields};

                if (!fields[field].value) {
                    fields[field].value = "+7";
                    this.setState({fields});
                }

            }
        }
    }

    onCapchaBlur = (e) => {
        var capcha = {...this.state.capcha};
        capcha.error = e.target.value ? (e.target.value.toLowerCase() == this.state.capcha.text.toLowerCase() ? "" : transl[process.env.REACT_APP_LANG].FormCaptchaIncorrectlyText) : transl[process.env.REACT_APP_LANG].FormCaptchaRequiredField;
        this.setState({capcha});
    }

    onCheckboxChange = () => {
        this.setState((prevState) => {
            return {
                confirm: !prevState.confirm
            }
        }, () => {
            this.setState({isValid: this.isValid()});
        })
    }

    isValid = () => {
        var {
            fields,
            confirm,
            capcha
        } = this.state;

        if (!confirm || capcha.value.toLowerCase() !== capcha.text.toLowerCase()) {
            return false;
        }
        for (var key in fields) {
            if ((fields[key].required && !fields[key].value) || fields[key].error) return false;
        }
        return true;
    }

    renderField = (name) => {
        var field = {...this.state.fields[name]};

        switch (field.type) {
            case "text":
                return <div className={"FormGroup"+
                (field.groupClassName ? " "+field.groupClassName : "")+
                (field.error ? " FormGroup_error" : "")+(field.value ? " filled" : "")}>
                    <input className={field.className}
                           type="text"
                           placeholder={field.label}
                           onChange={this.onChange(name)}
                           onBlur={this.onBlur(name)}
                           onFocus={this.onFocus(name)}
                           value={field.value}
                    />
                    <span className="error">{field.error}</span>
                    <label className="dynamic-label">{field.label}</label>
                </div>;
            case "textarea":
                return <div className={"Form__txt"+(field.error ? " FormGroup_error" : "")}>
                    <textarea className={field.className}
                              placeholder={field.label}
                              onChange={this.onChange(name)}
                              onBlur={this.onBlur(name)}
                              value={field.value}
                    />
                    <span className="error">{field.error}</span>
                </div>;
        }
    }

    phoneMask = (value) => {
        var next = value.replace(/[^+0-9]/gim, '');

        var arr = next.split('');

        if (arr[0] && arr[0] == 8) {
            arr.splice(0, 1, '+');
            arr.splice(1, 0, 7);
        }
        if (arr[0] && arr[0] != '+') {
            arr.splice(0, 0, '+');
        }
        if (arr[1] && arr[1] != 7) {
            arr.splice(1, 0, 7);
        }
        arr = arr.slice(0,12);
        var str = '';
        for (var i = 0; i < arr.length; i++) {
            switch (i) {
                case 2:
                case 5:
                case 8:
                case 10:
                    str += ' '+arr[i];
                    break;
                default:
                    str += arr[i];
            }
        }
        return str;
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({isValid: false});

        var fields = {...this.state.fields};

        var data = new FormData();
        for (var attr in fields) {
            data.append(attr, attr === "phone" ? fields[attr].value.replace(/\D/gim, '') : fields[attr].value);
        }

        fetch(apiUrl, {
            method: 'POST',
            body: data
        }).then((response) => {
            if (response.status === 200) {
                this.setState({isSent: true});
                return null;
            }
            return response.json();
        }).then((data) => {
            if (data) {
                var fields = {...this.state.fields};
                data.map((err) => {
                    fields[err.field].error = err.message;
                });
                return this.setState({fields});
            }
        }).catch(alert);
    }

    onReset = () => {
        this.setState(this.getInitState());
        if (this.context.modal) this.context.toggleModal();
    }

    renderOkContent = (className = "") => {
        return <div className={"form-ok"+(className ? " "+className : "")}>
            <Icon viewBox="0 0 146 146" icon="ok" />
            <div className="title" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].FormOkContent01 }} />
            <div dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].FormOkContent02 }} />
            <button className="btn btn--DarkBlue btn--DarkBlue-indent" onClick={this.onReset}>OK</button>
        </div>
    }

    render(){

        let {
            className = "",
            id="",
            isModal = true
        } = this.props;

        if (this.state.isSent && isModal) {
            return this.renderOkContent(className);
        }

        return <div className={"Form "+(className ? " "+className : "")}>
            {isModal ? <h2 className="modal-title" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].FormCapt }}/> : null}
            <form action="" method="POST" className="Form" onSubmit={this.onSubmit}>

                <div className="Form__TopString">
                    {this.renderField("name")}
                    {this.renderField("phone")}
                </div>

                {this.renderField("company")}

                {this.renderField("message")}

                <div className="FormSubmit">
                    <div className="FormCaptchaTest capcha">
                        {this.state.capcha.text}
                        <button type="button" className="reload-capcha" onClick={this.reloadCapcha}>
                            <Icon viewBox="0 0 22 22" icon="reload-icon" />
                        </button>
                    </div>
                    <input className={ transl[process.env.REACT_APP_LANG].FormCaptchaClass }
                           type="text"
                           placeholder={ transl[process.env.REACT_APP_LANG].FormFieldCode }
                           onChange={this.onCapchaChange}
                           onBlur={this.onCapchaBlur}
                           value={this.state.capcha.value}
                    />
                    <span className="capcha-error">{this.state.capcha.error}</span>
                    <button type="submit"
                            className={"btn btn--form"+(this.state.isValid ? "" : " disabled")}
                    >
                        { transl[process.env.REACT_APP_LANG].BtnSend }
                    </button>
                </div>

                <div className="FormConsent">
                    <input className="FormConsent-checkbox" onChange={this.onCheckboxChange} id={id+"checkbox"} type="checkbox"/>
                    <label className="FormConsent-label" htmlFor={id+"checkbox"}>
                        { transl[process.env.REACT_APP_LANG].FormFieldPersonalData01 }
                        <a href="https://buroburo.ru/policy/" target="_blank" onClick={(e) => {e.stopPropagation()}} dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].FormFieldPersonalData02 }}/>.</label>
                </div>

            </form>
            {this.state.isSent && !isModal ? <Modal className="success-modal" onClose={this.onReset}>
                {this.renderOkContent(className)}
            </Modal> : null}
        </div>
    }

}