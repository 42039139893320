import React, {Component} from "react";

export default class CaseEnGridScreen extends Component{

    render(){

        let {
            itemOne,
            itemTwo
        } = this.props;

        return <div className="case-en-grid-screen">
            <div className="wrap">

                <div className="grid-screen-one">
                    {itemOne.map((item, key) => <img key={ key } src={ item.img } alt="img-grid"/>)}
                </div>
                <div className="grid-screen-two">
                    {itemTwo.map((item, key) => <img key={ key } src={ item.img } alt="img-grid"/>)}
                </div>

            </div>
        </div>
    }

}