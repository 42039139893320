import React, {Component} from "react";

export default class Accorderon extends Component{

    constructor(props){
        super(props);

        this.state = {
            active: 0
        }
    }

    setActive = (num) => {
        return () => {
            this.setState({active: num})
        }
    }

    render(){
        return <div className="accorderon">
            {this.props.children.map((item, key) => <div className={"accordeon-item"+(this.state.active === key ? " active" : "")}
                                                         onClick={this.setActive(this.state.active === key ? null : key)}
                                                         key={key}
            >
                {item}
            </div>)}
        </div>
    }
}
