import React, {Component} from "react";
import sprite from "../img/sprite.svg";

import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);


export default class Advantages extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"Advantages "+(className ? " "+className : "")}>
            <div className="wrap wrap--indent">
                <h2 className="Advantages__title" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesCapt}} />

                <div className="Advantages__block">
                    <div className="AdvantagesIco">
                        <Icon viewBox="0 0 120 120" icon="security" />
                    </div>
                    <div className="AdvantagesCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesDscr01}} />

                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead01 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan01}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash AdvantagesDsc__Dash--top8"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead02 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan02}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead03 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan03}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash AdvantagesDsc__Dash--tp8"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead04 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan04}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash AdvantagesDsc__Dash--tp4"></div>
                        <div className="AdvantagesDsc__Cont">{ transl[process.env.REACT_APP_LANG].AdvantagesSubhead05 }</div>
                    </div>
                </div>

                <div className="Advantages__block">
                    <div className="AdvantagesIco">
                        <Icon viewBox="0 0 120 120" icon="fast" />
                    </div>
                    <div className="AdvantagesCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesDscr02}} />
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead06 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan06}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead07 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan07}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead08 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan08}} />
                        </div>
                    </div>
                </div>

                <div className="Advantages__block">
                    <div className="AdvantagesIco">
                        <Icon viewBox="0 0 120 120" icon="size" />
                    </div>
                    <div className="AdvantagesCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesDscr03}}/>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead09 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan09}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead10 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan10}} />
                        </div>
                    </div>
                </div>

                <div className="Advantages__block">
                    <div className="AdvantagesIco">
                        <Icon viewBox="0 0 120 120" icon="flex" />
                    </div>
                    <div className="AdvantagesCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesDscr04}}/>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead11 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan11}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead12 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan12}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash AdvantagesDsc__Dash--lf1"></div>
                        <div className="AdvantagesDsc__Cont"  dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubhead13}}/>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash AdvantagesDsc__Dash--lf1"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead14 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan14}} />
                        </div>
                    </div>
                </div>

                <div className="Advantages__block">
                    <div className="AdvantagesIco">
                        <Icon viewBox="0 0 120 120" icon="integration" />
                    </div>
                    <div className="AdvantagesCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesDscr05}}/>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead15 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan15}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead16 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan16}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead17 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan17}} />
                        </div>
                    </div>
                    <div className="AdvantagesDsc">
                        <div className="AdvantagesDsc__Dash"></div>
                        <div className="AdvantagesDsc__Cont">
                            { transl[process.env.REACT_APP_LANG].AdvantagesSubhead18 }
                            <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].AdvantagesSubheadSpan18}} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }

}




