import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import sprite from "../img/sprite.svg";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

let modalContainer = document.getElementById('modal');

export default class Modal extends Component {

	onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

	render() {
		let {
			className = "",
			onClose,
            children
		} = this.props;

		return ReactDOM.createPortal(
			<div className={"modal-container "+className} onClick={onClose}>
				<div className="modal-wrapper" onClick={this.onClick}>
					<button onClick={onClose} className="close" title="Закрыть">
                        <Icon viewBox="0 0 18 18" icon="menu-close" />
                    </button>
					{children}
				</div>
			</div>, modalContainer);
	}
}
