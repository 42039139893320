import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class CaseEnOtherCases extends Component{

    render(){

        let {
            className = "",
            items
        } = this.props;

        return <div className={"siemens-other-cases "+(className ? " "+className : "")}>
            <div className="other-cases__capt">{ items.capt }</div>
            <div className="other-cases__img-blocks">
                {items.otherCasesTxt.map((item, key) => <div key={key} className={"img-blocks "+ item.classBg}>

                    {item.linkExternal === false ?
                        <a href={item.link} target="_blank">
                            <span dangerouslySetInnerHTML={{__html: item.capt}} />
                        </a> :
                        <Link to={item.link} target="_self">
                            <span dangerouslySetInnerHTML={{__html: item.capt}} />
                        </Link>}

                </div>)}
            </div>
        </div>
    }

}