import React, {Component} from "react";
import PropTypes from "prop-types";

import Particles from "./Particles";
import BuroDataTxt from "./BuroDataTxt";
import ClassicScheme from "./ClassicScheme";
import Approach from "./Approach";
import BuroDataTransf from "./BuroDataTransf";
import Capability from "./Capability";
import Spheres from "./Spheres";
import Advantages from "./Advantages";
import SliderProj from "./SliderProj";
import ApplicationForm from "./ApplicationForm";
import Form from "./Form";

export default class Main extends Component{

    static contextTypes = {
        setHeaderTheme: PropTypes.func,
        setFooterTheme: PropTypes.func,
        modal: PropTypes.bool,
        screenSm: PropTypes.bool,
        toggleModal: PropTypes.func
    }

    static childContextTypes = {
        isFirstScreen: PropTypes.bool
    };

    getChildContext() {
        return {
            isFirstScreen: this.state.isFirstScreen
        };
    }

    constructor(props){
        super(props);
        this.state = {
            isFirstScreen: !props.location.hash,
            pageYOffset: 0
        }
    }

    componentDidMount(){
        if (this.props.location.hash){

            var item = document.getElementById(this.props.location.hash.substr(1));

            if (item){

                this.context.setFooterTheme("dark");

                if (document.readyState === "complete") {
                    window.scrollTo(0, item.offsetTop - 50);
                } else {
                    window.addEventListener("load", () => {
                        window.scrollTo(0, item.offsetTop - 50);
                    });
                }
            }

        } else {
            this.context.setHeaderTheme("");
            this.context.setFooterTheme("dark");
            if (document.readyState === "complete") {
                window.scrollTo(0, 0);
            } else {
                window.addEventListener("load", () => {window.scrollTo(0, 0)});
            }
        }

        if (this.state.isFirstScreen && !this.context.screenSm) {
            let scrollWidth = window.innerWidth - document.body.clientWidth;
            document.body.classList.add("unscrollable");
            document.body.setAttribute('style', 'padding-right:' + scrollWidth + 'px');
        }

        if (this.context.modal) this.context.toggleModal();
        window.addEventListener("scroll", this.onScroll);
        window.addEventListener("DOMMouseScroll", this.onMouseWheel);
        window.addEventListener("mousewheel", this.onMouseWheel);
    }

    componentWillReceiveProps(nextProps){
        if (this.props.location.hash != nextProps.location.hash){

            var item = document.getElementById(nextProps.location.hash.substr(1));
            if (item){
                document.getElementsByTagName('html')[0].classList.add("smooth");
                this.scrollTo(item);
                setTimeout(() => {
                    document.getElementsByTagName('html')[0].classList.remove("smooth")
                }, this.state.isFirstScreen && !this.context.screenSm ? 1200 : 500);
            } else {
                window.scrollTo(0,0);
                this.context.setHeaderTheme("");
                this.context.setFooterTheme("dark");
            }
            if (this.context.modal) this.context.toggleModal();
        }
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("DOMMouseScroll", this.onMouseWheel);
        window.removeEventListener("mousewheel", this.onMouseWheel);
        window.scrollTo(0,0);
    }

    onMouseWheel = (e) => {
        var isFirstScreen = this.state.isFirstScreen;
        if (isFirstScreen && (e.deltaY > 0 || e.detail > 0)) {
            e.preventDefault();
            var isFirstScreen = false;
            this.setState({isFirstScreen, pageYOffset: window.pageYOffset}, () => {
                if (!this.context.screenSm) {
                    setTimeout(() => {
                        document.body.classList.remove("unscrollable");
                        document.body.removeAttribute('style');
                    }, 500);
                }
            });

            this.context.setHeaderTheme("dark");
        }
        if (!isFirstScreen && (e.deltaY < 0 || e.detail < 0) && window.pageYOffset < 10) {
            e.preventDefault();
            var isFirstScreen = true;

            this.setState({isFirstScreen, pageYOffset: window.pageYOffset}, () => {
                if (!this.context.screenSm) {
                    let scrollWidth = window.innerWidth - document.body.clientWidth;
                    document.body.classList.add("unscrollable");
                    document.body.setAttribute('style', 'width: calc(100% - ' + scrollWidth + 'px)');
                }
            });

            this.context.setHeaderTheme("");
        }
    }

    onScroll = () => {
        var isFirstScreen = this.state.isFirstScreen;

        if (window.pageYOffset > 0 && isFirstScreen) {
            isFirstScreen = false;
            this.setState({isFirstScreen, pageYOffset: window.pageYOffset}, () => {
                if (!this.context.screenSm) {
                    setTimeout(() => {
                        document.body.classList.remove("unscrollable");
                        document.body.removeAttribute('style');
                    }, 1000);
                }
            });
        } else if (window.pageYOffset == 0 && !isFirstScreen) {
            isFirstScreen = true;
            this.context.setHeaderTheme("");
            this.setState({isFirstScreen, pageYOffset: window.pageYOffset}, () => {
                if (!this.context.screenSm) {
                    let scrollWidth = window.innerWidth - document.body.clientWidth;
                    document.body.classList.add("unscrollable");
                    document.body.setAttribute('style', 'width: calc(100% - ' + scrollWidth + 'px)');
                }
            });
        }

        if (!isFirstScreen) {
            var whiteStart = document.getElementById("capability").offsetTop;
            var whiteEnd = document.getElementById("partners").offsetTop;

            if (window.pageYOffset < whiteStart || window.pageYOffset > whiteEnd) {
                this.context.setHeaderTheme("dark");
            } else {
                this.context.setHeaderTheme("light");
            }
        }
    }

    getIsIE = () => {
        var ua = window.navigator.userAgent.toLowerCase();
        return (/trident/gi).test(ua) || (/msie/gi).test(ua);
    }

    scrollTo = (toItem) => {
        if (this.state.isFirstScreen && !this.context.screenSm) {
            this.setState({isFirstScreen: false}, () => {
                setTimeout(() => {
                    document.body.classList.remove("unscrollable");
                    document.body.removeAttribute('style');
                }, 300);

                setTimeout(() => {
                    window.scrollTo(0, toItem.offsetTop - 0.25*window.innerHeight);
                }, 750)
            });
        } else {
            window.scrollTo(0, toItem.offsetTop - 50);
        }
    }

    render(){
        return <div className={"main"+(this.state.isFirstScreen ? "" : (this.getIsIE() ? " transition-ie" : " transition"))}>
            <section><Particles /></section>
            <section>
                <BuroDataTxt className="wrap wrap--indent BuroDataTxt--indent" />
                <ClassicScheme history={this.props.history}/>
                <Approach className="wrap wrap--indent" />
                <BuroDataTransf className="wrap wrap--indent BuroDataTransf--indent" />
                <Capability  history={this.props.history}/>
                <Spheres />
                <Advantages />
                <SliderProj className="wrap wrap--indent"/>
                <ApplicationForm className="wrap wrap--indent">
                    <Form id={"checkOne"} isModal={false}/>
                </ApplicationForm>
            </section>
        </div>
    }
}
