import React, {Component} from "react";

import transl from "./Transl";

export default class approach extends Component{

  render(){

    let {
      className = ""
    } = this.props;

    return <div className={"Approach"+(className ? " "+className : "")}>

        <div className="Approach__ApproachCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].ApproachCapt}} />
        <div className="Approach__ApproachTxt">
            <p className="ApproachTxt__capt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].ApproachPrg}} />
            <div className="ApproachTxt__list">
                <div className="ApproachTxt__list-block">
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach01}} />
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach02}} />
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach03}} />
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach04}} />
                </div>
                <div className="ApproachTxt__list-block">
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach05}} />
                    <span dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach06}} />
                    <span className="ApproachTxt__list-block--mnone" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Approach07}} />
                </div>

            </div>
        </div>

    </div>
  }
}