import React, {Component} from "react";

import transl from "./Transl";

export default class Spheres extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        return <div className={"Spheres "+(className ? " "+className : "")}>
            <div className="wrap wrap--indent">
                <h3 className="Spheres__capt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].SpheresCapt}} />

                <div className="Spheres__block">

                    <ul className="Spheres__blockItem">
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres01}} />
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres02}} />
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres03}} />
                    </ul>

                    <ul className="Spheres__blockItem">
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres04}} />
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres05}} />
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres06}} />
                    </ul>

                    <ul className="Spheres__blockItem">
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres07}} />
                        <li dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].Spheres08}} />
                    </ul>

                </div>
            </div>
        </div>
    }

}