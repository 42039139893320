import React, {Component} from "react";
import sprite from "../img/sprite.svg";


import Slider from "react-slick";
import Accorderon from "./Accorderon";

import transl from "./Transl";
import {Link} from "react-router-dom";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

function SampleNextArrow({onClick}) {
    return (
        <div className="SliderBtn__next" onClick={onClick}>
            <Icon viewBox="0 0 19 30" icon="arrbow-bold" />
        </div>
    );
}

function SamplePrevArrow({onClick}) {
    return (
        <div className="SliderBtn__prev" onClick={onClick}>
            <Icon viewBox="0 0 19 30" icon="arrbow-bold" />
        </div>
    );
}

export default class SliderProj extends Component {

    constructor(props){
        super(props);

        this.state = {
            screenXs: window.innerWidth < 768
        }

        this.settings = {
            dots: true,
            infinite: true,
            speed: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrow: true,
            adaptiveHeight: true,
            nextArrow: <SampleNextArrow onClick={() => {this.slider.slickNext()}}/>,
            prevArrow: <SamplePrevArrow onClick={() => {this.slider.slickPrev()}}/>
        };

        this.slides = [
            {
                external: transl[process.env.REACT_APP_LANG].SiemensLinkExternalRu,
                title: "Siemens",
                // link: "https://siemens.finance/",
                link: transl[process.env.REACT_APP_LANG].SliderProjSiemensLink,
                linkAttribute: transl[process.env.REACT_APP_LANG].linkAttribute,
                includes: [
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt05,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt06
                ],
                services: [
                    transl[process.env.REACT_APP_LANG].SliderProjSrv07,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv08,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv09
                ]
            },
            {
                external: false,
                title: "Raiffeisen Capital",
                // link: "https://www.raiffeisen-capital.ru/",
                link: transl[process.env.REACT_APP_LANG].SliderProjRaiffeisenLink,
                linkAttribute: '_blank',
                includes: [
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt01,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt02,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt03,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt04
                ],
                services: [
                    transl[process.env.REACT_APP_LANG].SliderProjSrv01,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv02,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv03,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv04,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv05,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv06
                ]
            },
            {
                external: true,
                title: transl[process.env.REACT_APP_LANG].SliderProMotive,
                link: "https://motivtelecom.ru/",
                linkAttribute: '_blank',
                includes: [
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt07,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt08,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt09,
                    transl[process.env.REACT_APP_LANG].SliderProjCombTxt10
                ],
                services: [
                    transl[process.env.REACT_APP_LANG].SliderProjSrv10,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv11,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv12,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv13,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv14,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv15,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv16,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv17,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv18,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv19,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv20,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv21,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv22,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv23,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv24,
                    transl[process.env.REACT_APP_LANG].SliderProjSrv25
                ]
            }
        ];
    }

    componentDidMount(){
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        if (this.state.screenXs !== window.innerWidth < 768) {
            this.setState({screenXs: window.innerWidth < 768});
        }
    }

    setSlide = (slide) => {
        this.slider && this.slider.slickGoTo(this.slides.indexOf(slide));
    }

    renderSlides = () => {
        return this.slides.map((item, key) => <div key={key}>
            <div className="Slider Slider--indent">

                {this.slides.map((slide, index) => {
                    const slidesCount = this.slides.length;
                    const slideItem = (this.slides[key + index] || this.slides[key + index - slidesCount]);
                    return <h2 className="SliderCont" key={index} onClick={ event => this.setSlide(slideItem) }>{ (slideItem || slide).title }</h2>
                })}

            </div>

            {item.external === true ? <a href={item.link} target={item.linkAttribute} className="SliderProjLink">{ transl[process.env.REACT_APP_LANG].SliderProjLink }
                    <Icon viewBox="0 0 18 10" icon="arrow-blue" />
            </a> :
            <Link to={item.link} className="SliderProjLink">
                { transl[process.env.REACT_APP_LANG].SliderProjLink }
                <Icon viewBox="0 0 18 10" icon="arrow-blue" />
            </Link>}

            <div className="SlderBottomBlock">
                <div className="PlatformCombines">
                    <div className="Platform__capt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].SliderProjComb}}/>

                    <div className="Platform__list Platform__list--left">
                        {item.includes.map((inc, incKey) => <span key={incKey}>{inc}</span>)}
                    </div>
                </div>
                <div className="PlatformUnite">
                    <div className="Platform__capt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].SliderProjUnit}}/>
                    <div className="Platform__list Platform__list--unite">
                        {item.services.map((serv, servKey) => <span key={servKey}>{serv}</span>)}

                    </div>
                </div>
            </div>
        </div>)
    }

    render() {

        let {
            className = ""
        } = this.props;

        return <div id="partners" className={"SliderProj " + (className ? " " + className : "")}>

            <div className="SliderTitle">
                <h3 className="SliderCapt" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].SliderProjSubTtl}} />
            </div>

            {this.state.screenXs ? <Accorderon>
                {this.renderSlides()}
            </Accorderon> : <Slider {...this.settings} ref={(slider) => {this.slider = slider}}>
                {this.renderSlides()}
            </Slider>}
        </div>
    }

}