import React, {Component} from "react";

export default class CaseEnFirstScrTxt extends Component{

    render(){

        let {
            className = ""
        } = this.props;

        let {
            clientItem,
            serviceTxt01,
            serviceTxt02,
            serviceTxt03,
            serviceLink01,
            serviceLink02,
            serviceLink03,
            txtCapt01,
            txtPar01,
            txtCapt02,
            txtPar02,
        } = this.props.firstScreenTxt;

        return <div className={"en-first-scr-txt "+(className ? " "+className : "")}>
            <div className="scr01-txt__dsc">

                <div className="scr01-txt__dsc--block">
                    <span className="scr01-txt__dsc--client-name">Client</span>
                    <span className="scr01-txt__dsc--brand">{clientItem}</span>
                </div>

                <div className="scr01-txt__dsc--block">
                    <span className="scr01-txt__dsc--client-name">Service</span>
                    <div className="scr01-txt__dsc--brand-block">
                        <a className="scr01-txt__dsc--brand" href={serviceLink01} target="_blank">{serviceTxt01}</a>
                        <span className="dsc--brand__dot"/>
                        <a className="scr01-txt__dsc--brand" href={serviceLink02}>{serviceTxt02}</a>
                        {serviceLink03 ? <React.Fragment>
                            <span className="dsc--brand__dot"/>
                            <a className="scr01-txt__dsc--brand" href={serviceLink03}>{serviceTxt03}</a>
                        </React.Fragment> : null}
                    </div>
                </div>

            </div>

            <div className="case-txt__par">

                {txtCapt01 ? <div className="txt-capt" dangerouslySetInnerHTML={{__html: txtCapt01}} /> : null}
                <div className="txt-par" dangerouslySetInnerHTML={{__html: txtPar01}} />

            </div>

            <div className="case-txt__par">

                <div className="txt-capt" dangerouslySetInnerHTML={{__html: txtCapt02}} />
                <div className="txt-par" dangerouslySetInnerHTML={{__html: txtPar02}} />

            </div>

        </div>
    }

}