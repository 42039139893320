import React from "react";
import {Link} from "react-router-dom";

export default {

    'en': {
        //index.html -->
        indexTitle: 'BuroData Platform',
        indexMetaDiscr: 'BuroData Platform',
        indexMetaKeywords: 'BuroData Platform',
        //index.html --end

        //button group -->
        BtnLeaveInquiry: 'Leave an inquiry',
        BtnSeeScheme: 'See the scheme',
        BtnSend: 'Send',
        BtnBack: 'Back',
        //button group --'end'

        //Particles.js -->
        ParticlesCapt: 'Multifunctional web-platform that automates the exchange of&nbsp;information between different microservices and data sources and allows you to&nbsp;manage them through a&nbsp;single web-interface and automatically create a&nbsp;RESTful API.',
        //Particles.js --'end'

        //Nav.js -->
        NavLinkOne: 'Managing data.',
        NavLinkTwo: 'Possibilities.',
        NavLinkThree: 'Already with us.',
        NavLinkFour: 'Ru',
        NavLinkEn: 'https://burodata.ru/',
        //Nav.js --'end'

        //BuroDataTxt.js -->
        BuroDataTxtCapt: 'Add data in&nbsp;different formats from different systems and output the data obtained and systematized by&nbsp;the platform to&nbsp;various projects without additional integrations: personal accounts, mobile applications, online stores, etc.',
        //BuroDataTxt.js --'end'

        //ClassicScheme.js -->
        ClassicSchemeCapt: 'A&nbsp;conventional working with data scheme',
        //ClassicScheme.js --'end'

        //Approach.js -->
        ApproachCapt: 'Cons of&nbsp;the classical approach',
        ApproachPrg: 'In&nbsp;the case of&nbsp;classical development processes, it&nbsp;is&nbsp;necessary to&nbsp;develop multiple integration layers to&nbsp;work with each system.',
        Approach01: 'Development time',
        Approach02: 'Development cost',
        Approach03: 'Difficulty maintaining',
        Approach04: 'Poor scalability',
        Approach05: 'Poor fault tolerance',
        Approach06: 'Low flexibility in&nbsp;working with the product',
        Approach07: 'Low security of data',
        //Approach.js --'end'

        //BuroDataTransf.js -->
        BuroDataTransfCapt: 'In&nbsp;the modern era of&nbsp;digital transformations, the use of&nbsp;AI and economical manufacturing approaches, deep integration with all services and systems is&nbsp;necessary. In&nbsp;the case of&nbsp;classical development processes, it&nbsp;is&nbsp;necessary to&nbsp;develop multiple integration layers to&nbsp;work with each system.',
        //BuroDataTransf.js --'end'

        //Capability.js -->
        CapabilityCapt: 'Features BuroData Platform',
        CapabilityDscr: 'The BuroData Platform allows you to&nbsp;avoid all the difficulties regarding a&nbsp;project since the solution is&nbsp;built <br className="capabil--br"/>on&nbsp;a&nbsp;unified web-platform using a&nbsp;&laquo;Single Window&raquo; for integrating all of&nbsp;the necessary systems.',
        //Capability.js --'end'

        //Spheres.js -->
        SpheresCapt: 'Spheres of&nbsp;application',
        Spheres01: 'Telecom',
        Spheres02: 'Financial sector',
        Spheres03: 'Insurance companies',
        Spheres04: 'Retail',
        Spheres05: 'Online shopping',
        Spheres06: 'Corporate Portals',
        Spheres07: 'The medicine',
        Spheres08: 'Projects working with large amounts of&nbsp;data',
        //Spheres.js --'end'

        //Advantages.js -->
        AdvantagesCapt: 'Key Benefits of&nbsp;the BuroData Platform',
        AdvantagesDscr01: 'Reliability of&nbsp;data and high fault tolerance',
        AdvantagesSubhead01: 'Full-fledged storage ',
        AdvantagesSubheadSpan01: '(fault-tolerant file storage based on&nbsp;latest technologies)',
        AdvantagesSubhead02: 'Load balancing ',
        AdvantagesSubheadSpan02: '(balancing between servers and clusters)',
        AdvantagesSubhead03: 'Monitoring ',
        AdvantagesSubheadSpan03: '(monitoring of the entire infrastructure and response with the necessary notifications)',
        AdvantagesSubhead04: 'Strict observance of the Federal Law-152 ',
        AdvantagesSubheadSpan04: '(Federal Law “On Personal Data”)',
        AdvantagesSubhead05: 'Built-in utilities to control data security.',
        AdvantagesDscr02: 'Speed ​​performance',
        AdvantagesSubhead06: 'Speed ',
        AdvantagesSubheadSpan06: '(API responses are ten times faster than the standard php application)',
        AdvantagesSubhead07: 'High Performance Web Server ',
        AdvantagesSubheadSpan07: '(Balanced Server)',
        AdvantagesSubhead08: 'Caching services ',
        AdvantagesSubheadSpan08: '(caching service)',
        AdvantagesDscr03: 'Scalability',
        AdvantagesSubhead09: 'Modularity ',
        AdvantagesSubheadSpan09: '(ability to&nbsp;add necessary and remove unnecessary modules)',
        AdvantagesSubhead10: 'Fast scalability ',
        AdvantagesSubheadSpan10: '(we deploy a functioning application on a remote server with one click of a button)',
        AdvantagesDscr04: 'Flexibility and speed regarding working with the product',
        AdvantagesSubhead11: 'Support for relational and NoSQL databases ',
        AdvantagesSubheadSpan11: '(we support all popular databases)',
        AdvantagesSubhead12: 'Queue service ',
        AdvantagesSubheadSpan12: '(message queuing system)',
        AdvantagesSubhead13: 'Native Java, Python, PHP, Ruby, NodeJS and Go&nbsp;Applications',
        AdvantagesSubhead14: 'Using systems for automatic configuration of server hardware ',
        AdvantagesSubheadSpan14: '(installing the necessary software on the server with one click)',
        AdvantagesDscr05: 'Easy to&nbsp;use and integrate with your business',
        AdvantagesSubhead15: 'Development of mobile applications ',
        AdvantagesSubheadSpan15: '(focus on&nbsp;mobile development and connect the platform to&nbsp;the application in&nbsp;a&nbsp;few mouse clicks)',
        AdvantagesSubhead16: 'Web application development ',
        AdvantagesSubheadSpan16: '(develop a&nbsp;website, and the platform will assume all responsibilities for managing big data, users, their authorization, etc.)',
        AdvantagesSubhead17: 'Simple administration ',
        AdvantagesSubheadSpan17: '(all products used in the platform are familiar to Linux system administrators)',
        AdvantagesSubhead18: 'Built-in services and APIs ',
        AdvantagesSubheadSpan18: '(the standard package already includes the necessary modules, such as&nbsp;authorization, registration, authorization, and connection to&nbsp;databases)',
        //Advantages.js --'end'

        //SliderProj -->
        SliderProjSiemensLink: '/siemens',
        SiemensLinkExternalRu: false,
        SliderProjRaiffeisenLink: '/raiffeisen',
        linkAttribute: '_self',
        SliderProMotive: 'Motive',
        SliderProjSubTtl: 'Who we&nbsp;already work with',
        SliderProjLink: 'See the project',
        SliderProjSiemensLink: 'https://burodata.com/projects/siemens_finance/',
        SliderProjComb: 'A unified web<br/>platform combining:',
        SliderProjCombTxt01: 'Corporate website',
        SliderProjCombTxt02: 'Authorization system',
        SliderProjCombTxt03: 'Personal Account (Client, Agent)',
        SliderProjCombTxt04: 'Mobile application (IOS, Android)',
        SliderProjCombTxt05: 'Personal account (client, manager)',
        SliderProjCombTxt06: 'Mobile application (IOS, Android)',
        SliderProjCombTxt07: 'A corporate site (B2C, B2B)',
        SliderProjCombTxt08: 'Operator clients self-service system (B2C, B2B)',
        SliderProjCombTxt09: 'Online store',
        SliderProjCombTxt10: 'Mobile application (IOS, Android)',
        SliderProjUnit: 'BuroData Platform<br/>combines services:',
        SliderProjSrv01: 'Accounting system',
        SliderProjSrv02: 'ERP',
        SliderProjSrv03: 'DaData',
        SliderProjSrv04: 'Interfax',
        SliderProjSrv05: 'sms service',
        SliderProjSrv06: 'identification and authentication system',
        SliderProjSrv07: 'ERP',
        SliderProjSrv08: 'CRM',
        SliderProjSrv09: 'MSSQL',
        SliderProjSrv10: 'Accounting system',
        SliderProjSrv11: 'CRM',
        SliderProjSrv12: 'BPM',
        SliderProjSrv13: 'Billing',
        SliderProjSrv14: 'Goods and balances accounting system',
        SliderProjSrv15: 'Courier service for courier booking',
        SliderProjSrv16: 'DaData',
        SliderProjSrv17: 'identification and authentication system',
        SliderProjSrv18: 'HH.ru',
        SliderProjSrv19: 'Recommendation system',
        SliderProjSrv20: 'System of marketing requests',
        SliderProjSrv21: 'Processing, for payment on-line',
        SliderProjSrv22: 'SMS-service',
        SliderProjSrv23: 'Contact center system',
        SliderProjSrv24: 'System for issuing electronic cash receipts',
        SliderProjSrv25: 'Product catalogue (tariffs, services, goods)',
        //SliderProj --'end'

        //Form -->
        FormCapt: 'Apply now and join the BuroData Platform',
        FormFieldName: 'Name',
        FormFieldPhone: 'Phone',
        FormFieldCompany: 'Company',
        FormFieldMessage: 'Your message',
        FormFieldCode: 'Captcha code',
        FormFieldPersonalData01: 'I hereby certify that I have read and agree to',
        FormFieldPersonalData02: '&nbsp;the personal data processing policy',
        FormCaptchaClass: 'FormCaptchaEn',
        FormCaptchaPhone: 'Enter a valid phone number',
        FormCaptchaRequiredField: 'Required field',
        FormCaptchaIncorrectlyText: 'Incorrectly entered text from the picture',
        FormOkContent01: 'Thanks for the address!',
        FormOkContent02: 'Usually we answer within two-three hours in working hours',
        //Form --'end'

        //Footer -->
        FooterCpr01: 'Made in',
        FooterCpr02: '&nbsp;бюробюро&nbsp;',
        FooterCpr03: 'with',
        //Footer --'end'

        //SchemeClassical -->
        SchemeClassicalCapt: 'Typical scheme of data handling',
        SchemeClassicalLabel01: '—  Integration layer',
        SchemeClassicalLabel02: '—  Database',
        SchemeClassicalImg: '../img/shema-dark@3x-En.png',
        SchemeClassicalEn: true,
        //SchemeClassical --'end'

        //SchemeCapability -->
        SchemeCapabilityCapt: 'BuroData Platform capabilities',
        SchemeCapabilityEn: true,
        //SchemeCapability --'end'
    },

    'ru': {
        //index.html -->
        indexTitle: 'BuroData Platform — многофункциональная web-платформа, для управления, сбора и обмена большим объемом данных.',
        indexMetaDiscr: 'BuroData Platform — единая, интегрированная web-платформа для управления, сбора и обмена большим объемом данных.',
        indexMetaKeywords: 'интернет платформа, web платформа, web platforma, единая веб платформа',
        //index.html --end

        //button group -->
        BtnLeaveInquiry: 'Оставить заявку',
        BtnSeeScheme: 'Смотреть схему',
        BtnSend: 'Отправить',
        BtnBack: 'Вернуться назад',
        //button group --'end'

        //Particles.js -->
        ParticlesCapt: 'Многофункциональная web-платформа, которая автоматизирует обмен информацией между различными микросервисами и&nbsp;источниками данных и&nbsp;позволяет управлять ими через единый web-интерфейс и&nbsp;автоматически создавать RESTful API.',
        //Particles.js --'end'

        //Nav.js -->
        NavLinkOne: 'Работа с данными.',
        NavLinkTwo: 'Возможности.',
        NavLinkThree: 'Кто с нами.',
        NavLinkFour: 'En',
        NavLinkEn: 'https://burodata.com/',
        //Nav.js --'end'

        //BuroDataTxt.js -->
        BuroDataTxtCapt: 'Добавляйте данные в&nbsp;разных форматах из&nbsp;разных систем и&nbsp;выводите полученные&nbsp;и систематизированные платформой данные&nbsp; на различные проекты без дополнительных интеграций: личные кабинеты, мобильные приложения, интернет-магазины и&nbsp;т.д.',
        //BuroDataTxt.js --'end'

        //ClassicScheme.js -->
        ClassicSchemeCapt: 'Классическая схема работы с&nbsp;данными',
        //ClassicScheme.js --'end'

        //Approach.js -->
        ApproachCapt: 'Минусы классического подхода',
        ApproachPrg: 'В&nbsp;классической разработке приходится разрабатывать множество интеграционных слоев для работы с&nbsp;каждой системой.',
        Approach01: 'Время разработки',
        Approach02: 'Cтоимость разработки',
        Approach03: 'Cложность в&nbsp;обслуживании',
        Approach04: 'Плохая масштабируемость',
        Approach05: 'Плохая отказоустойчивость',
        Approach06: 'Нет гибкости в&nbsp;работе с&nbsp;продуктом',
        Approach07: 'Низкая безопасность хранения данных',
        //Approach.js --'end'

        //BuroDataTransf.js -->
        BuroDataTransfCapt: 'В&nbsp;эпоху диджитал трансформации, использования искусственного интелекта и&nbsp;подходов бережливого производства, необходима глубокая интеграция со&nbsp;всеми сервисами и&nbsp;системами.',
        //BuroDataTransf.js --'end'

        //Capability.js -->
        CapabilityCapt: 'Возможности BuroData Platform',
        CapabilityDscr: 'Платформа позволяет избежать всех трудностей на&nbsp;проекте, т.к. решение построено <br className="capabil--br"/>на&nbsp;единой web-платформе с&nbsp;&laquo;Одним окном&raquo; для интеграции всех необходимых систем.',
        //Capability.js --'end'

        //Spheres.js -->
        SpheresCapt: 'Сферы применения',
        Spheres01: 'Телеком',
        Spheres02: 'Финансовый сектор',
        Spheres03: 'Страховые компании',
        Spheres04: 'Ритейл',
        Spheres05: 'Интернет магазины',
        Spheres06: 'Корпоративные порталы',
        Spheres07: 'Медицина',
        Spheres08: 'Проекты с&nbsp;большим объемом данных',
        //Spheres.js --'end'

        //Advantages.js -->
        AdvantagesCapt: 'Основные преимущества платформы',
        AdvantagesDscr01: 'Надежность данных <br/>и&nbsp;высокая отказоустойчивость',
        AdvantagesSubhead01: 'Полноценное хранилище ',
        AdvantagesSubheadSpan01: '(отказоустойчивое хранилище файлов на&nbsp;основе последних технологий)',
        AdvantagesSubhead02: 'Балансировка нагрузки ',
        AdvantagesSubheadSpan02: '(балансировка между серверами и&nbsp;кластерами)',
        AdvantagesSubhead03: 'Мониторинг ',
        AdvantagesSubheadSpan03: '(мониторинг всей инфраструктуры и&nbsp;реакция с&nbsp;необходимыми уведомлениями)',
        AdvantagesSubhead04: 'Строгое соблюдение ФЗ-152 ',
        AdvantagesSubheadSpan04: '(федеральный закон &laquo;О&nbsp;персональных данных&raquo;)',
        AdvantagesSubhead05: 'Встроенные утилиты для контроля безопасности данных.',
        AdvantagesDscr02: 'Быстродействие',
        AdvantagesSubhead06: 'Быстродействие ',
        AdvantagesSubheadSpan06: '(ответы API быстрее в&nbsp;десятки раз стандартного приложения на&nbsp;php)',
        AdvantagesSubhead07: 'Высокопроизводительный web-сервер ',
        AdvantagesSubheadSpan07: '(Сервер с&nbsp;функцией балансировки)',
        AdvantagesSubhead08: 'Сервисы кэширования ',
        AdvantagesSubheadSpan08: '(сервис кэширования)',
        AdvantagesDscr03: 'Масштабируемость',
        AdvantagesSubhead09: 'Модульность ',
        AdvantagesSubheadSpan09: '(возможность добавлять необходимые и&nbsp;удалять ненужные модули)',
        AdvantagesSubhead10: 'Быстрая масштабируемость ',
        AdvantagesSubheadSpan10: '(разворачиваем готовое приложение на&nbsp;удаленном сервере одним нажатием кнопки)',
        AdvantagesDscr04: 'Гибкость и&nbsp;скорость в&nbsp;работе<br/>с&nbsp;продуктом',
        AdvantagesSubhead11: 'Поддержка реляционных и&nbsp;nosql баз данных ',
        AdvantagesSubheadSpan11: '(поддерживаем все популярные базы данных)',
        AdvantagesSubhead12: 'Сервис очередей ',
        AdvantagesSubheadSpan12: '(система очередей сообщений)',
        AdvantagesSubhead13: 'Собственные приложения на&nbsp;Java, Python, PHP, Ruby, NodeJS и&nbsp;Go ',
        AdvantagesSubhead14: 'Использование систем автоматической настройки серверного оборудования ',
        AdvantagesSubheadSpan14: '(установка необходимого&nbsp;ПО на&nbsp;сервера одной кнопкой)',
        AdvantagesDscr05: 'Простота в&nbsp;использовании <br/>и&nbsp;интеграции с&nbsp;вашим бизнесом',
        AdvantagesSubhead15: 'Разработка мобильных приложений ',
        AdvantagesSubheadSpan15: '(сосредоточьтесь на&nbsp;мобильной разработке и&nbsp;подключите платформу в&nbsp;приложение в&nbsp;несколько кликов мышью)',
        AdvantagesSubhead16: 'Разработка веб приложений ',
        AdvantagesSubheadSpan16: '(разрабатывайте веб-сайт, а&nbsp;платформа возьмет на&nbsp;себя все обязанности по&nbsp;управлению большими данными, пользователями, их&nbsp;авторизацией и&nbsp;пр.)',
        AdvantagesSubhead17: 'Простое администрирование ',
        AdvantagesSubheadSpan17: '(все продукты, используемые в&nbsp;платформе, знакомы системным администраторам Linux)',
        AdvantagesSubhead18: 'Встроенные сервисы и&nbsp;API ',
        AdvantagesSubheadSpan18: '(в&nbsp;стандартной пакет уже включены необходимые модули, например авторизация, регистрация, авторизация, и&nbsp;подключение к&nbsp;базам данных)',
        //Advantages.js --'end'

        //SliderProj -->
        SliderProjSiemensLink: 'https://buroburo.ru/projects/lizing-siemens-finance/',
        SiemensLinkExternalRu: true,
        SliderProjRaiffeisenLink: 'https://buroburo.ru/projects/raiffeisen-lk/',
        linkAttribute: '_blank',
        SliderProMotive: 'Мотив',
        SliderProjSubTtl: 'Уже с нами',
        SliderProjLink: 'Смотреть проект',
        SliderProjSiemensLink: 'https://buroburo.ru/projects/lizing-siemens-finance/',
        SliderProjComb: 'Единая Web-платформа сочетающая в себе:',
        SliderProjCombTxt01: 'Корпоративный сайт',
        SliderProjCombTxt02: 'Система авторизации',
        SliderProjCombTxt03: 'Личный кабинет (Клиента, агента )',
        SliderProjCombTxt04: 'Мобильное приложение (IOS, Android)',
        SliderProjCombTxt05: 'Личный кабинет (клиент, менеджер)',
        SliderProjCombTxt06: 'Мобильное приложение (IOS, Android)',
        SliderProjCombTxt07: 'Корпоративный сайт (B2C, B2B)',
        SliderProjCombTxt08: 'Система самообслуживания абонентов Оператора (B2C, B2B)',
        SliderProjCombTxt09: 'Интернет магазин',
        SliderProjCombTxt10: 'Мобильное приложение (IOS, Android)',
        SliderProjUnit: 'BuroData Platform<br/>объединяет сервисы:',
        SliderProjSrv01: '1С',
        SliderProjSrv02: 'Interfax',
        SliderProjSrv03: 'КИС',
        SliderProjSrv04: 'MFMS',
        SliderProjSrv05: 'DaData',
        SliderProjSrv06: 'ЕСИА',
        SliderProjSrv07: 'ERP',
        SliderProjSrv08: 'CRM',
        SliderProjSrv09: 'MSSQL',
        SliderProjSrv10: '1С',
        SliderProjSrv11: 'CRM',
        SliderProjSrv12: 'BPM',
        SliderProjSrv13: 'Биллинг',
        SliderProjSrv14: 'Система учета товаров и остатков',
        SliderProjSrv15: 'Курьерская служба для бронирования курьера',
        SliderProjSrv16: 'DaData',
        SliderProjSrv17: 'ЕСИА',
        SliderProjSrv18: 'HH.ru',
        SliderProjSrv19: 'Рекомендательная система',
        SliderProjSrv20: 'Система маркетинговых заявок',
        SliderProjSrv21: 'Процессинг, для оплаты  on-line',
        SliderProjSrv22: 'SMS сервис',
        SliderProjSrv23: 'Система контакт центра',
        SliderProjSrv24: 'Система для выставления электронных кассовых чеков',
        SliderProjSrv25: 'Товарный каталог (тарифы, услуги, товары)',
        //SliderProj --'end'

        //Form -->
        FormCapt: 'Заявка на подключение BuroData Platform',
        FormFieldName: 'Имя',
        FormFieldPhone: 'Телефон',
        FormFieldCompany: 'Компания',
        FormFieldMessage: 'Текст сообщения',
        FormFieldCode: 'Код с картинки',
        FormFieldPersonalData01: 'Настоящим подтверждаю, что я ознакомлен и согласен с',
        FormFieldPersonalData02: '&nbsp;политикой обработки персональных данных',
        FormCaptchaClass: 'FormCaptcha',
        FormCaptchaPhone: 'Введите корректный номер телефона',
        FormCaptchaRequiredField: 'Обязательное поле',
        FormCaptchaIncorrectlyText: 'Неверно введен текст с картинки',
        FormOkContent01: 'Спасибо за обращение!',
        FormOkContent02: 'Обычно мы отвечаем в течение дня!',
        //Form --'end'

        //Footer -->
        FooterCpr01: 'Сделано в',
        FooterCpr02: '&nbsp;бюробюро&nbsp;',
        FooterCpr03: 'с',
        //Footer --'end'

        //SchemeClassical -->
        SchemeClassicalCapt: 'Классическая схема работы с данными',
        SchemeClassicalLabel01: '—  Интеграционный слой',
        SchemeClassicalLabel02: '—  База данных',
        SchemeClassicalImg: '../img/shema-dark.png',
        SchemeClassicalEn: false,
        //SchemeClassical --'end'

        //SchemeCapability -->
        SchemeCapabilityCapt: 'Возможности BuroData Platform',
        SchemeCapabilityEn: false,
        //SchemeCapability --'end'
    }
};