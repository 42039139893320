import React, {Component} from "react";

export default class CaseEnInfo extends Component{

    render(){

        let {
            className = "",
            type,
            items,
            phonesItem,
            scenariosOne,
            scenariosTwo,
        } = this.props;

        let result = "";

        switch(type) {

            case "typeA": {
                result = <div className={"case-en-info "+ (className ? " "+className : "")}>
                        <div className="siemens-img-wrap wrap-img-block">
                            <img className={ scenariosOne.class } src={ scenariosOne.img } alt="img"/>
                        </div>
                        <div className="wrap--in">

                            <div className="customer-area__txt scenarios-txt-block-one">
                                <div className="txt-capt" dangerouslySetInnerHTML={{__html: scenariosOne.capt}}/>
                                { scenariosOne.description.map((env, key) => <div key={ key } className="txt-par"  dangerouslySetInnerHTML={{__html: env.txt}} />) }
                            </div>

                        </div>

                        <div className="scenarios-img-block wrap--indent">
                            <img src={ scenariosTwo.img } alt="img" className="raiff-scenarios-img"/>
                        </div>

                        <div className="wrap--in">

                            <div className="customer-area__txt scenarios-txt-block-two">
                                { scenariosTwo.description.map((env, key) => <div key={ key } className="txt-par"  dangerouslySetInnerHTML={{__html: env.txt}} />) }
                            </div>

                        </div>

                    </div>
            }
                break;
            case "typeB": {
                result = <div className={"case-en-info "+ (className ? " "+className : "")}>
                    <div className="siemens-img-wrap">
                        <img className={ phonesItem.classImg } src={ phonesItem.img } alt="img"/>
                    </div>
                    <div className="wrap--in">
                        {phonesItem.phonesDsc.map((env, key) => <div key={key} className="phones__txt">
                            {env.description.map((item, key) => <React.Fragment key={key}>
                                {item.capt ? <div className="txt-capt" dangerouslySetInnerHTML={{__html: item.capt}}/> : null}
                                {item.txt ? <div className="txt-par" dangerouslySetInnerHTML={{__html: item.txt}} /> : null}
                            </React.Fragment>)}
                        </div>)}
                    </div>
                </div>
            }
                break;
            default: {
                result = <div className="case-en-info">
                    {items.img ? <div className={"siemens-img-wrap"+ (className ? " "+className : "")}>
                        {!items.img320 ? <img className={ items.class } src={ items.img } alt="img"/> :
                            <React.Fragment>
                                <img className={ items.class } src={ items.img } alt="img"/>
                                <img className="client-img320" src={ items.img320 } alt="img"/>
                            </React.Fragment>
                        }
                    </div> : null}
                    <div className="wrap--in">
                        <div className="customer-area__txt">
                            <div className="txt-capt" dangerouslySetInnerHTML={{__html: items.capt}}/>
                            { items.description.map((env, key) => <div key={ key } className="txt-par"  dangerouslySetInnerHTML={{__html: env.txt}} />) }
                        </div>
                    </div>
                </div>
            }
                break;

        }

        return <React.Fragment>
            { result }
        </React.Fragment>
    }

}