import React, {Component} from "react";
import {Link} from "react-router-dom";

import scheme from "../img/classicalScheme@2x.png";

import transl from "./Transl";

export default class ClassicScheme extends Component{

    constructor(props){
        super(props);
        this.state = {
            animate: false
        }
    }

    componentDidMount(){
        this.setState({
            animate: window.pageYOffset + window.innerHeight/2 > document.getElementById("schema").offsetTop
        });
        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        var animate = window.pageYOffset + window.innerHeight/2 > document.getElementById("schema").offsetTop;
        if (!this.state.animate && animate) {
            this.setState({animate});
        }
    }

    render(){

        let {
            className = "",
            history
        } = this.props;

        return <div id="schema" className={"ClassicScheme"+(className ? " "+className : "")}>

            <div className="SecondaryCaption">
                <h2 className="SecondaryCaptionTitle SecondaryCaptionTitle--ClassicScheme" dangerouslySetInnerHTML={{__html: transl[process.env.REACT_APP_LANG].ClassicSchemeCapt}} />
                <Link to="/classic"
                      className="btn btn--transparent btn--ClassicScheme-indent"
                      onClick={(e) => {
                          e.preventDefault();
                          history.replace("/#schema");
                          history.push("/classic");
                      }}
                >
                    { transl[process.env.REACT_APP_LANG].BtnSeeScheme }
                </Link>
            </div>

            <div className={"ClassicScheme__bg-mono"+(this.state.animate ? " animate" : "")}/>
            <img className="ClassicScheme__bg-img" src={scheme}/>
        </div>
    }
}