import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import scheme from "../img/shema-light.png";
import schemeEn from "../img/shema-light@3x-En.png";
import sprite from "../img/sprite.svg";

import transl from "./Transl";

const Icon = (props) => (
    <svg viewBox={props.viewBox} >
        <use xlinkHref={`${sprite}#${props.icon}`} />
    </svg>
);

export default class SchemeCapability extends Component{

    constructor() {
        super();

        this.state = {
            shemEN: transl[process.env.REACT_APP_LANG].SchemeCapabilityEn,
        }
    }

    static contextTypes = {
        setHeaderTheme: PropTypes.func,
        setFooterTheme: PropTypes.func
    }

    componentDidMount(){
        this.context.setHeaderTheme("light");
        this.context.setFooterTheme("light");
    }

    render(){

        let {
            className = ""
        } = this.props;

        let {
            shemEN
        } = this.state;

        return <div className={"SchemeCapability "+(className ? " "+className : "")}>

            <div className="wrap wrap--scheme">

                <Link className="Scheme-link" to="/#capability">
                    <Icon viewBox="0 0 60 60" icon="back" />
                    { transl[process.env.REACT_APP_LANG].BtnBack }
                </Link>
                <div className="Scheme-head">
                    <div className="Scheme-head__capt Scheme-head__capt--light">
                        { transl[process.env.REACT_APP_LANG].SchemeCapabilityCapt }
                    </div>
                </div>
            </div>
            {shemEN ? <img className="ShemaEn Shema--Light Scheme-head--light" src={schemeEn}/> :
                <img className="Shema Shema--Light Scheme-head--light" src={scheme}/> }

        </div>
    }

}